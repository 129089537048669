.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

:root {
  --dsg-border-color: #e8ebed;
  --dsg-selection-border-color: rgb(69, 128, 230);
  --dsg-selection-border-radius: 2px;
  --dsg-selection-border-width: 2px;
  --dsg-selection-background-color: rgba(69, 128, 230, 0.04);
  --dsg-selection-disabled-border-color: #9da6ab;
  --dsg-selection-disabled-background-color: rgba(0, 0, 0, 0.04);
  --dsg-corner-indicator-width: 10px;
  --dsg-header-text-color: rgb(157, 166, 171);
  --dsg-header-active-text-color: black;
  --dsg-cell-background-color: white;
  --dsg-cell-disabled-background-color: rgb(250, 250, 250);
  --dsg-transition-duration: 0.1s;
  --dsg-expand-rows-indicator-width: 10px;
  --dsg-scroll-shadow-width: 7px;
  --dsg-scroll-shadow-color: rgba(0, 0, 0, 0.2);
  
}

.dsg-cell-header-container {
  padding: 0 0px !important;
  line-height: 15px ;
}
.dsg-container {
  height:  700px !important 
}

